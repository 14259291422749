.main-content {
  text-align: center;
  margin: 100px auto 50px auto;
  display: grid;
  grid-template-columns: 300px 1fr 300px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
  
  @media (width <= 950px) {
    .main-content {
      width: auto;
      margin: 50px;
      display: block;
    }
  }
  
  @media (width <= 320px) {
    .main-content {
      margin: 20px;
      flex-direction: column-reverse;
    }
  }