.header-сontainer {
    box-sizing: border-box;
    z-index: 100;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 10px 30px;
    background-color: var(--main-color);
    box-shadow: 0px 0px 25px var(--main-color);
}

.hiding-button-container {
    display: none;
    background:  var(--main-color);
    max-width: 40px;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-radius: 0 0 5px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
}    

.hiding-button {
    width: 15px;
    height: 17px;
    margin: 0 auto;
}

.hiding-button svg {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    transition: all 0.5s;
}

.hiding-button.arrow-to-right svg {
    transform: rotate(-90deg);
}

.hiding-button svg path{
    stroke-width: 5;
    stroke: var(--light-color)
}

.zodiacs-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.zodiacs-container ul{
    list-style-type: none;
    display: flex;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.zodiac-link {
    background: var(--secondary-color);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.1s;
}

.zodiac-link.active {
    background: var(--light-color);
}

.zodiac-link img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (width <= 950px) {
    .hiding-button-container {
        display: block;
    }
    
    .header-сontainer {
        position: fixed;
        width: auto;
        height: 100%;
        padding: 45px 20px;
        top: 0;
        bottom: 0;
        max-width: 130px;
        transition: all 0.5s;
    }

    .header-сontainer.hiden {
        padding: 0;
        max-width: 0;
        opacity: 0;
    }

    .header-сontainer.hiden .zodiac-link{
        width: 0;
    }

    .zodiacs-container {
        width: auto;
    }

    .zodiacs-container ul{
        gap: 0px;
        flex-direction: column;
    }

    .zodiac-link {
        width: 100%;
        text-align: left;
        background: var(--main-color);
        color: var(--secondary-color)
    }
    
    .zodiac-link.active {
        background: var(--main-color);
        color: var(--light-color);
    }

    .zodiac-link p {
        margin: 0;
    }
}