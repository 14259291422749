.tooltip::after {
	background: #12141C75;
	border-radius: var(--border-radius);
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
	color: var(--light-color);
	content: attr(data-tooltip);
	margin-top: 25px;
	opacity: 0;
	padding: 3px 7px;
	position: absolute;
	visibility: hidden;
	transition: all 0.2s ease-in-out;
}
		
.tooltip:hover::after {
	opacity: 1;
	visibility: visible;
}