* {
    box-sizing: border-box;
    font-family: "Noto Sans";
}
    
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-image: url(./assets/stars.svg), linear-gradient(rgba(0 ,0, 36, 0.7), rgba(255, 255, 255));
  background-repeat: repeat-x;
}

:root {
  --header-height: 90px;

  /* COLORS */
  --main-color: #12141C;
  --secondary-color: #8e979f;
  --third-color: #1b2750;
  --accent-color: #5D72BC;
  --light-color: #cfd5ec;

  --hover-primary-color: #af0000;
  --hover-secondary-color: #fff9f9;

  --active-primary-color: #8a0000;
  --active-secondary-color: #fee3e3;

  /* FONT COLORS */
  --primary-font-color: black;
  --secondary-font-color: #828282;
  --third-font-color: white;
  --accent-font-color: var(--main-color);

  --hover-primary-font-color: #fee3e3;
  --hover-secondary-font-color: #8a0000;

  --border-radius: 5px;
}

h1, p {
  margin: 10px 0;
}

p {
  font-size: 1.3rem;
}

button {
  border: none;
  background: none;
  border-radius: var(--border-radius);
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
}

button.contained {
  background-color: var(--main-color);
  color: var(--third-font-color)
}

button.contained:hover {
  background-color: var(--hover-primary-color);
  color: var(--hover-primary-font-color);
}

button.contained:active {
  background-color: var(--active-primary-color);
  color: var(--hover-primary-font-color);
}

button.outlined {
  color: var(--accent-font-color);
  border: 1px solid var(--main-color);
}

button.outlined:hover {
  background-color: var(--hover-secondary-color);
  color: var(--hover-primary-color);
  border: 1px solid var(--hover-primary-color);
}

button.outlined:active {
  background-color: var(--active-secondary-color);
}

input, textarea {
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  width: 100%;
  margin-bottom: 10px;
  padding: 3px 10px;
  font-size: 1rem;
  box-sizing: border-box;
}

input:focus-visible,
textarea:focus-visible {
  border: 2px solid var(--secondary-color);
  outline: none;
}

a {
  color: var(--accent-font-color)
}