.banner-box {
    width: 300px;
    height: 100%;
}

@media (width <= 950px) {
    .banner-box {
        width: 100%;
        height: 300px;
    }
}